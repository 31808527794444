import React from "react";
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BannerSlider(props) {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
    <Container sx={{overflow:'hidden', padding:'12px 8px 25px 8px'}} maxWidth="sm">
        <Slider {...settings}>
            <Card><img src="./musket-web-banner-rookies-vapor.webp" style={{width:'100%'}}></img></Card>
            <Card><img src="./centaurus-web-banner-rookies-vapor.webp" style={{width:'100%'}}></img></Card>
        </Slider>
    </Container>
      
    );
  }

export default BannerSlider;