import React, { useState,useEffect,useRef } from 'react';
import { getFetch,getFetchProduct } from './lib/api/common';
import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled, alpha, useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { Button, CardActionArea, CardActions, colors } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuIcon from '@mui/icons-material/Menu';


import CurrencyFormat from 'react-currency-format';
import InfiniteScroll from 'react-infinite-scroller';


import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import DirectionsIcon from '@mui/icons-material/Directions';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BannerSlider from './component/BannerSlider';




const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.4),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 0.5, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(0.5em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('xs')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const CardComponent = (props) => {
  const {id,name,sell_price,sell_price_pos,photo_md,photo_xs}=props.data
  const isSold = props.isSold;
  const imgRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const src = img.getAttribute("data-src");
          img.setAttribute("src", src);
          img.classList.add("fade");
          observer.disconnect();
        }
      });
    });

    observer.observe(imgRef.current);
  });
  return (
    <Card sx={isSold ? {filter:'opacity(0.5)'} : null}>
          <CardActionArea id={id} onClick={props.action} >
            <CardMedia
              component="img"
              loading="lazy"
              image={photo_xs}
              data-src={photo_md}
              alt={name}
              ref={imgRef}
              
            />
            <CardContent
              sx={{
                padding: '10px 5px',
            }} >
              <Typography 
              sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  height:'40px'
              }} 
              variant="body2" 
              color="text.secondary">
              {name}
              </Typography>
              <Typography 
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  fontWeight:'bolder'
                }} 
                variant="body2" 
                color="text.secondary">
                <CurrencyFormat value={Number(sell_price)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'}  />
                { Number(sell_price) < Number(sell_price_pos) ? 
                <CurrencyFormat style={{textDecoration: 'line-through', paddingLeft:'5px', fontSize:'0.8rem', color:'lightgray', overflow:'hidden', textOverflow:'ellipsis'}} value={Number(sell_price_pos)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'} />
              : null}
                
              </Typography>
              <Typography 
                sx={{
                  textDecoration: 'line-through red',
                  textAlign:'right'
                }} 
                variant="body1" 
                color="text.secondary">
                
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
          </CardActions> */}
        </Card>
  );
};

const customLocalStorage = {
    getItem(key)
    {
        // getting the data from localStorage using the key
        let result=JSON.parse(window.localStorage.getItem(key));
        if(result)
        {
            /*
                if data expireTime is less then current time
                means item has expired,
                in this case removing the item using the key
                and return the null.
            */
            if(result.expireTime<=Date.now())
            {
                window.localStorage.removeItem(key);
                return null;
            }
            // else return the data.
            return result.data;
        }
        //if there is no data provided the key, return null.
        return null;
    },
    /*
        accepting the key, value and expiry time as a parameter
        default expiry time is 30 days in milliseconds.
    */
    setItem(key, value, maxAge=30*30*60*1000)
    {
        // Storing the value in object.
        let result=
        {
            data:value
        }
        if(maxAge)
        {
            /*
                setting the expireTime currentTime + expiry Time 
                provided when method was called.
            */
            result.expireTime=Date.now()+maxAge;
        }
        window.localStorage.setItem(key,JSON.stringify(result));
    },
    removeItem(key)
    {
        window.localStorage.removeItem(key);
    },
    clear()
    {
        window.localStorage.clear();
    }
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return(
    <Fade onExited={() => {
    }} direction="up" ref={ref} {...props} timeout={500} /> 
  )
});
const TransitionTop = React.forwardRef(function Transition(props, ref) {
  return(
    <Fade direction="down" ref={ref} {...props} timeout={500} /> 
  )
});

function App(props) {
  const [appState,setState] = useState({
    display:'product_list',
    productList:{data:[]},
    isLoaded : false,
    detailDialogShown : false,
    isProductDetailLoaded : false,
    productDetail:{data:[]},
    links:{},
    meta:{},
    searchProduct:'',
    searchText:'',
    hasMore:false,
    isDrawerOpen:false,
    categoryList:[],
    showCategoryId:'',
    showCategoryName:'',
    hash:false,
    alert:{
      open:false,
      message:''
    },
    dialogResult:false,
    isAgeVerified:{
      status:true,
      verified:false
    },
    testTOKEN:''
  });
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#252525',
      },
      secondary: {
        main: '#ea1014',
      },
      divider: 'rgba(47,45,45,0.12)',
    },
  });

  const handleClickOpen = (id) => {
    setState(prevState => {
      return { ...prevState, detailDialogShown: true}
    });
    window.location.hash = `#detail?id=${id}`;
    const productDetail = getFetch(`/product/detail?id=${id}`);
    const productVariants = getFetch(`/productvariantonlysearch?search_column[]=product_id&search_text[]=${id}`);
    Promise.all([productDetail, productVariants]).then(function(response) {
      setState(prevState => {
        const variantsImage = {};
        response[1].data.map(({sku,photo_md},key)=>{
          return variantsImage[sku]= photo_md
        })
        return { ...prevState, isProductDetailLoaded: true, productDetail:{...response[0].data,variantsImage}}
      });
    }).catch((error)=>{
      setState((prefState)=>{
        return{...prefState,
          alert:{
            show:true,
            message:'Terjadi kesalahan saat menampilkan data detail'
          }
        }
      })
    });
  };

  const handleClose = () => {
    window.history.back();
    setState(prevState => {
      return { ...prevState, detailDialogShown: false}
    },setTimeout(()=>{
      setState(prevState => {
        // console.log('reset')
        return { ...prevState,isProductDetailLoaded:false,productDetail:{data:[]}}
      })
    },500))
  };

  const resetSearch = () =>{
    setState(prefState => {
      return {...prefState,searchProduct:'',isLoaded:false,productList:{data:[]}, hasMore:false,showCategoryName:''}
    }, getFetchProduct('/product').then((response)=>{
        setState(prevState => {
          const productList = response,
                links = response.links, 
                meta = response.meta
          let hasMore = true;
          if(meta.current_page > meta.last_page){
            hasMore = false;
          }
          window.scrollTo(0, 0);
          return { ...prevState, productList,links,meta,hasMore, isLoaded:true }
        });
    }))
  }

  const HandleVerifyAge = (e) =>{
    const today = dayjs();
    if(today.diff(e,'year') >= 21){
      customLocalStorage.setItem('isAgeVerified', true, 60*60*1000);
      setState((prefState)=>{
        return{...prefState,isAgeVerified:{
          status:true,
          verified:true
        },
        dialogResult :true
      }
      })
    } else {
      customLocalStorage.removeItem('isAgeVerified');
      setState((prefState)=>{
        return{...prefState,isAgeVerified:{
          status:true,
          verified:false
        },
        dialogResult :true
      }
      })
    }
  }

  const handleChangeBranch = (e) =>{
    const branch = e.target.value;
    if ( branch === 1) {
      window.location.href = 'https://www.rookiesvapor.com/';
    } else if ( branch === 2) {
      window.location.href = 'https://cikupa.rookiesvapor.com/';
    }
  }

  useEffect(()=> {

    const ageVerifiedStored = customLocalStorage.getItem('isAgeVerified');
    let isAgeVerified,dialogResult;
    if(ageVerifiedStored){
      isAgeVerified = {
        status:true,
        verified:true
      }
      dialogResult = true;
    } else {
      isAgeVerified = {
        status:false,
        verified:false
      }
      dialogResult = false;
    }

    const onHashChange = () => {
      if (window.location.hash === ''){
        setState(prevState => {
          return { ...prevState, detailDialogShown: false}
        },setTimeout(()=>{
          setState(prevState => {
            return { ...prevState,isProductDetailLoaded:false,productDetail:{data:[]}}
          })
        },500))
      } else {
        setState((prevState)=>{
          return{
            ...prevState,hash: window.location.hash
          }
        })
      }
    }
    window.addEventListener("hashchange", onHashChange);
    window.location.hash = ''
    getFetchProduct('/product').then((response)=>{
      setState(prevState => {
        const {productList = response,links = response.links, meta = response.meta} = response;
        let hasMore = true;
        if(meta.current_page > meta.last_page){
          hasMore = false;
        }
        
        return { ...prevState, productList,links,meta,hasMore, isLoaded:true, isAgeVerified, dialogResult }
      });
    },getFetch('/productgroup?per_page=20').then((response)=>{
        setState(prevState => {
          const categoryList = response.data;
          
          return { ...prevState, categoryList}
        });
    }, ()=>{return () => window.removeEventListener("hashchange", onHashChange);})).then(()=>{if(ageVerifiedStored){openDialogResult(2000,true)}})
  }, [])

  const loadFunc = ()=>{
    if(appState.meta.current_page !== undefined && appState.hasMore){
      if(appState.searchProduct === '' && appState.showCategoryId === ''){ 
          getFetchProduct(`/product?`,`page=${Number(appState.meta.current_page)+1}`).then((response)=>{
            setState(prevState => {
              const productList = {...prevState.productList, data:prevState.productList.data.concat(response.data)};
              const links = response.links;
              const meta = response.meta ;
              // console.log('meta', meta)
              return { ...prevState, productList,links,meta, isLoaded:true }
            });
          })
      } else if (appState.searchProduct === '' && appState.showCategoryId !== ''){
          getFetchProduct(`/product?`,`search_column[]=klasifikasi_id&search_text[]=${appState.showCategoryId}&page=${Number(appState.meta.current_page)+1}`).then((response)=>{
            setState(prevState => {
              const productList = {...prevState.productList,data:prevState.productList.data.concat(response.data)}
              const links = response.links
              const meta = response.meta
              let hasMore = true;
              if(meta.current_page > meta.last_page){
                hasMore = false;
              }
              return { ...prevState, productList,links,meta,hasMore, isLoaded:true }
            });
          })
      } else{
          getFetchProduct(`/product?`,`search_text[]=${appState.searchProduct}&search_column[]=name&page=${Number(appState.meta.current_page)+1}`).then((response)=>{
            setState(prevState => {
              const productList = {...prevState.productList,data:prevState.productList.data.concat(response.data)}
              const links = response.links
              const meta = response.meta
              let hasMore = true;
              if(meta.current_page > meta.last_page){
                hasMore = false;
              }
              return { ...prevState, productList,links,meta,hasMore, isLoaded:true }
            });
          })
    }
    }
  }
  const searchRef = useRef();
  const searchItem = (e)=>{
    if(e.key === 'Enter'){
      getFetchProduct(`/product`,`?search_column[]=name&search_text[]=${e.target.value}`).then((response)=>{
        const search = e.target.value
        e.target.blur();
        e.target.value='';
        setState(prevState => {
          const productList = response;
          const links = response.links;
          const meta = response.meta;
          let hasMore = true;
          if(meta.current_page > meta.last_page){
            hasMore = false;
          }
          window.scrollTo(0, 0);
          return { ...prevState, productList,links,meta, isLoaded:true,hasMore,searchProduct:search,searchText:'',showCategoryName:'' }
        });
      }).catch((error)=>{
        // console.error(JSON.parse(error.message));
        let productSearch = searchRef.current.value;
        searchRef.current.blur();
        searchRef.current.value ='';
        setState( (prefState)=>{
          return {...prefState,
            alert:{
              open:true,
              message:`Produk '${productSearch}' yang kamu cari tidak ditemukan`
            }
          }
        },setTimeout(closeAlert,5000))
      })
   }
  }
  const closeAlert = ()=>{
    
    setState((prefState)=>{
      return {...prefState,
        alert:{
          open:false,
          message:''
        }
      }
    })
  }

  const openDialogResult = (time,force=false)=>{
    if(force){
      setTimeout(closeDialogResultForce,time)
    } else {
      setTimeout(closeDialogResult,time)
    }
  }
  const closeDialogResult = ()=>{
    if(appState.isAgeVerified.verified){
      setState((prefState)=>{
        return {...prefState,
          dialogResult:false
        }
      })
    }
  }
  const closeDialogResultForce = ()=>{
    setState((prefState)=>{
      return {...prefState,
        dialogResult:false
      }
    })
  }
  const handleDrawerOpen = () => {
    setState((prefState)=>{
      return {...prefState,isDrawerOpen:true}
    });
  };

  const handleDrawerClose = () => {
    setState((prefState)=>{
      return {...prefState,isDrawerOpen:false}
    });
  };

  const clickCategory = (e,{id,name}) =>{
    setState((prefState)=>{
      return {...prefState, isLoaded : false, productList:{data:[]},links:{},meta:{},hasMore:false,showCategoryId:id,showCategoryName:name, searchProduct:''}
    },getFetchProduct(`/product`,`?search_column[]=klasifikasi_id&search_text[]=${id}`).then((response)=>{
      const productList = response,
            links = response.links, 
            meta = response.meta
      // console.log({productList,links,meta})
      let hasMore = true;
      if(meta.current_page > meta.last_page){
        hasMore = false;
      }
      // console.log({ isLoaded : false, productList,links,meta,hasMore})
      // console.log(appState)
      setState(((prevState)=>{
        return {...prevState, isLoaded :true, productList,links,meta,hasMore}
      }))
    }))
  }

  const list = (category) => {
    const exception = [230498,230496,230495,2307955,2307947,1244608];
    return (
      <Box
        role="presentation"
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
      >
        
        <List sx={{width:'65vw'}}>
          <ListSubheader component="div" id="nested-list-subheader">
              Rookies Vapor
          </ListSubheader>
          {category.map(({id,name,children},key)=>{
            if(children.length > 0){
              return(
                <React.Fragment key={key}>
                  {children.map(({id,name},key)=>{
                    return (
                      <React.Fragment key={key}>
                        <ListItemButton id={id} key={key}>
                          {/* <ListItemIcon>
                            <ShortcutIcon />
                          </ListItemIcon> */}
                          <ListItemText onClick={((e) => clickCategory(e, {id,name}))} data-id={id} primary={name} />
                        </ListItemButton>
                        {/* <Divider/> */}
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              )
            } else {
              if(!exception.includes(id)){
                return(
                  <React.Fragment key={key}>
                    <ListItem key={key} disablePadding>
                      <ListItemButton>
                        {/* <ListItemIcon>
                          <ShortcutIcon />
                        </ListItemIcon> */}
                        <ListItemText onClick={((e) => clickCategory(e, {id,name}))} data-id={id} primary={name} />
                      </ListItemButton>
                    </ListItem>
                    {/* <Divider/> */}
                  </React.Fragment>
                )
              }else{
                return <React.Fragment key={key}></React.Fragment>
              } 
            }
          })}     
        </List>
      </Box>
    )
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <Paper>
          <Box sx={{textAlign:'center',padding:'20px 0px 10px 0px', backgroundColor:(theme) => theme.palette.primary.main}}>
            <img style={{width:'100%',maxWidth:'150px'}} src={process.env.PUBLIC_URL+'/header-logo-noframe-rookies-vapor.png'}/>
          </Box>
        </Paper>
        <AppBar position="sticky" open={appState.isDrawerOpen}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <FormControl variant='outlined' sx={{ m: 1, width: 300, flexGrow:1 }}>
              <Select
                id="select-branch"
                value={1}
                onChange={handleChangeBranch}
                input={<OutlinedInput sx={{color:'whitesmoke'}} />}
                size='small'
              >
                  <MenuItem value={1}>
                    <Typography variant="body2" noWrap component="div"
                      sx={{ flexGrow: 1, textAlign:'center', display: { xs: 'block', sm: 'block' } }}
                    >
                      <PlaceIcon sx={{verticalAlign:'text-top', height:'16px',marginRight:'5px',marginLeft:'-20px'}}/>
                      Gading Serpong
                    </Typography>
                  </MenuItem>
                  <MenuItem value={2} disabled>
                    <Typography variant="body2" noWrap component="div"
                      sx={{ flexGrow: 1, textAlign:'center', display: { xs: 'block', sm: 'block' } }}
                    >
                      <PlaceIcon sx={{verticalAlign:'text-top', height:'16px',marginRight:'5px',marginLeft:'-20px'}}/>
                      Cikupa
                    </Typography>
                  </MenuItem>
              </Select>
            </FormControl>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Cari Produk.."
                inputProps={{ 'aria-label': 'search' }}
                onKeyUp={searchItem}
                inputRef={searchRef}
              />
            </Search>
          </Toolbar>
          {appState.searchProduct !== '' ?
            <Box 
              sx={{
                margin: "5px 8px 10px 8px",
                display: 'flex',
                height: '35px',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                    m: 1,
                  },
              }}
              >
              <SearchIcon />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Typography sx={{paddingLeft:'12px', flexGrow:'2'}}>{appState.searchProduct}</Typography>
              <IconButton aria-label="delete">
                <HighlightOffIcon onClick={resetSearch} />
              </IconButton>
            </Box> : null
          }
          {appState.showCategoryName !== '' ?
            <Box 
              sx={{
                margin: "5px 8px 10px 8px",
                display: 'flex',
                height: '35px',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                    m: 1,
                  },
              }}
              >
              <SearchIcon />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Typography sx={{paddingLeft:'12px', flexGrow:'2'}}>{appState.showCategoryName}</Typography>
              <IconButton aria-label="delete">
                <HighlightOffIcon onClick={resetSearch} />
              </IconButton>
            </Box> : null
          }
        </AppBar>
        {/* SECTION: side drawer category */}
        <SwipeableDrawer
              anchor={'left'}
              open={appState.isDrawerOpen}
              onClose={handleDrawerClose}
              onOpen={handleDrawerOpen}
            >
              {list(appState.categoryList)}
        </SwipeableDrawer>
      <main open={appState.isDrawerOpen}>
        {/* SECTION:loading block if product not loaded÷ */}
        <BannerSlider/>
        {!appState.isLoaded ? <Stack
          style={{height: "70vh"}}
          direction="row"
          justifyContent="center"
          alignItems="center"> 
          <CircularProgress color="success" /> 
          </Stack>: null
        }
        {/* SECTION: alert when search not found */}
        <Collapse in={appState.alert.open}>
          <Alert
          severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 1 }}
          >
            {appState.alert.message}
          </Alert>
        </Collapse>
        {/* SECTION: product container with scroll loader */}
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={appState.hasMore}
          loader={<div style={{textAlign:'center'}} className="loader" key={0}><CircularProgress color="success" /></div>}
          >
          <Grid container  spacing={0}>
            {appState.productList.data.map(({id,name,sell_price,sell_price_pos,photo_md,photo_xs,variants,stock_qty},key)=>{
              let isSold;
              if(variants.length > 0){
                let available = []
                variants.map(({stock_qty})=>{
                  if(stock_qty !== 0){
                    available.push(stock_qty)
                  }
                })
                isSold = available.length === 0
              } else {
                isSold = stock_qty === 0
              }
              return (
                <Grid xs={6} md={3} lg={2} key={key}style={{padding: "12px 8px"}}>
                  <CardComponent data={{id,name,sell_price,sell_price_pos,photo_md,photo_xs}} isSold={isSold} action={()=>{handleClickOpen(id)}}/>
                </Grid>
              )
            })}
          </Grid>
        </InfiniteScroll>
        {/* SECTION: bottom menu bar */}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            value={''}
          >
            <BottomNavigationAction target="_blank" href="https://www.instagram.com/rookies.vapor" label="Instagram" icon={<InstagramIcon />} />
            <BottomNavigationAction target="_blank" href="https://wa.me/+6285171670218" label="Chat Admin" icon={<WhatsAppIcon />} />
            <BottomNavigationAction target="_blank" href="https://maps.app.goo.gl/1q7V5jLxCgbR4Ftu5" label="Lokasi" icon={<DirectionsIcon />} />
          </BottomNavigation>
        </Paper>
      </main>
      <React.Fragment>
        {/* SECTION: dialog section for product detail */}
        <Dialog
          fullScreen
          open={appState.detailDialogShown && appState.hash}
          onClose={handleClose}
          TransitionComponent={Transition}
          scroll ={'body'}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {!appState.isProductDetailLoaded ? 
              <Stack
                  style={{height: "80vh"}}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"> 
                  <CircularProgress color="success" /> 
              </Stack>
              : 
              <>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} sx={ProductDetailStyle.container}>
                    <Grid item xs={12} md={6} sx={ProductDetailStyle.image}>
                      <img alt='appState.productDetail.photo_md' style={{width:'100%', maxWidth:'500px'}} src={appState.productDetail.photo_md}></img>
                    </Grid>
                    <Grid container item xs={12} md={6} sx={ProductDetailStyle.grid}>
                      <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="h6" component="div" style={{fontWeight:'bolder'}}>
                          <CurrencyFormat value={Number(appState.productDetail.sell_price)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'}  />
                          { Number(appState.productDetail.sell_price) < Number(appState.productDetail.sell_price_pos) ? 
                            <CurrencyFormat style={{textDecoration: 'line-through', paddingLeft:'5px', fontSize:'0.8rem', color:'lightgray', overflow:'hidden', textOverflow:'ellipsis'}} value={Number(appState.productDetail.sell_price_pos)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'} />
                          : null}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                          {appState.productDetail.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="h6" component="div" style={{fontWeight:'bolder'}}>
                          {appState.productDetail.variant_label}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} md={8} sx={{...ProductDetailStyle.grid,maxWidth:'500px'}}>
                          {appState.productDetail.variant.map(({name,sell_price,sell_price_pos,sku,stock_qty},key)=>{
                            if(appState.productDetail.variantsImage !== undefined){
                              return (
                                <Grid container item spacing={2} xs={12}  sx={stock_qty === 0 ? {...productVariantsStyle.container,alignItems: 'center'} : {alignItems: 'center'}} key={key}>
                                  <Grid item xs={2}>
                                    <Avatar sx={{ width: 56, height: 56 }} alt={name} variant="rounded" src={appState.productDetail.variantsImage[sku]} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography sx={{paddingLeft:'20px'}} gutterBottom variant="h7" component="div">
                                      {name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} sx={{ textAlign:'right' }}>
                                    <Typography gutterBottom variant="h7" component="div">
                                    <CurrencyFormat value={Number(sell_price)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'}  /><br/>
                                    { Number(sell_price) < Number(sell_price_pos) ? 
                                      <CurrencyFormat style={{textDecoration: 'line-through', paddingLeft:'5px', fontSize:'0.8rem', color:'lightgray', overflow:'hidden', textOverflow:'ellipsis'}} value={Number(sell_price_pos)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp'} />
                                    : null}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            }
                          })}
                        </Grid>
                      </Grid>
                    </Grid>               
                  </CardContent>
                </Card>
              </>
          }
        </Dialog>
        
        {/* SECTION: dialog section for input age verification */}
        <Dialog
          fullScreen
          open={!appState.isAgeVerified.status}
          TransitionComponent={Transition}
          TransitionProps={{onExited:()=>{openDialogResult(4000)}}}
          sx={{
            padding:'20px 25px',
            backgroundColor:'rgba(0,0,0,0.8)'
          }}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              display:'flex',
              justifyContent:'center'
            },
          }}
        >
          <Card sx={{
            backgroundColor:'black'
          }}>
            <CardContent sx={{color:'white',textAlign:'center'}}>
            <Typography variant='h5'>VERIFIKASI UMUR</Typography>
            <Typography variant='body2' sx={{marginBottom:'15px'}}>Untuk dapat mengakses situs ini, kamu harus mempunyai umur minimal 21 Tahun.</Typography>
            <Typography variant='body2' sx={{marginBottom:'5px'}}>Masukan tanggal lahirmu :</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                slotProps={{
                textField: {
                  size: "small",
                  sx:{
                    '.MuiOutlinedInput-root':{
                      color:'white'
                    },
                    'fieldset':{
                      borderColor:'rgba(255,255,255,0.5)'
                    }
                    
                  }
                },
                openPickerButton:{
                  sx:{
                      color:'rgba(255,255,255,0.5)'
                  }
                }
              }}
              onAccept={HandleVerifyAge}
              openTo="year"
              views={['year', 'month', 'day']}
              // defaultValue={dayjs('2022-04-17')}
                />
            </LocalizationProvider>
            <Typography variant='body2' sx={{marginTop:'25px',fontWeight:'bolder'}}>PERINGATAN: Produk ini mangandung nikotin. Nikotin adalah zat kimia adiktif yang menyebabkan ketergantungan</Typography>
            </CardContent>
          </Card>
        </Dialog>

        {/* SECTION: dialog section for result age verification */}
        <Dialog
          fullScreen
          open={appState.dialogResult}
          TransitionComponent={TransitionTop}
          sx={{
            padding:'20px 25px',
            backgroundColor: appState.isAgeVerified.verified ? 'transparent' : 'rgba(77,7,7,0.98)'
          }}
          slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0,0,0,0.2)' } } }}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              display:'flex',
              justifyContent:'center'
            },
          }}
        >

          <Card sx={{backgroundColor:'#252525'}}>
            {appState.isAgeVerified.verified ? 
              <CardContent sx={{color:'white',textAlign:'center'}}>
                <Typography variant='h1'><CheckCircleIcon sx={{color:'green', fontSize:'100px'}} /></Typography>
                <Typography variant='h5'>VERIFIKASI UMUR BERHASIL</Typography>
                <Typography variant='body2' sx={{marginBottom:'15px'}}>Terimakasih telah melakukan verifikasi, kalian dapat mengakses situs ini.</Typography>
                <Typography variant='body2' sx={{marginTop:'25px',fontWeight:'bolder'}}>*Kami akan menyimpan data verifikasi anda selama 1 jam. Verifikasi ulang di butuhkan di saat kamu mengakses situs ini lebih dari itu.</Typography>
              </CardContent>
            :
              <CardContent sx={{color:'white',textAlign:'center'}}>
                <Typography variant='h1'><CancelIcon sx={{color:'red', fontSize:'100px'}} /></Typography>
                <Typography variant='h5'>UMUR BELUM CUKUP</Typography>
                <Typography variant='body2' sx={{marginBottom:'15px'}}>Kalian harus berumur 21 Tahun untuk dapat mengakses website ini, silahkan tutup halaman ini.</Typography>
                <Typography variant='body2' sx={{marginTop:'25px',fontWeight:'bolder'}}></Typography>
              </CardContent>
            }
          </Card>
        </Dialog>
      </React.Fragment>
      </>
    </ThemeProvider>
  );
}

const ProductDetailStyle = {
  container:{
    justifyContent:'center'
  },
  image:{
    textAlign:'right',
    '@media (max-width: 900px)': {
      textAlign:'center',
    }
  },
  grid:{
    alignSelf:'flex-start',
    '@media (max-width: 900px)': {
      maxWidth:'500px',
      textAlign: 'left',
    },
  },
  paddingContainer:{
    padding:'10px 16px'
  },
  img:{
    width:'100%',
    maxWidth:'500px'
  }
}

const productVariantsStyle = {
  container:{
    position:'relative',
    '::before': {
      content: '"sold"',
      zIndex:'100',
      textAlign:'center',
      width:'100%',
      height:'100%',
      position:'absolute',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-around',
      color:'red',
      fontWeight:'bolder',
      textTransform :'uppercase'
    },
    '::after': {
      content: '""',
      position: 'absolute',
      top:'0',
      left:'0',
      bottom:'0',
      width: '100%',
      background : 'rgba(255, 255, 255, 0.9)'
    }
  }
}

export default App;
