import axios from 'axios';

const baseURL = process.env.REACT_APP_CATALOG_BASE_URL
const axiosInstance = axios.create({
    baseURL: `${baseURL}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(request => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return request;
  }, error => {
    return Promise.reject(error);
  });

  axiosInstance.interceptors.response.use(
    response => response, // Directly return successful responses.
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
        try {
          const refreshToken = sessionStorage.getItem('refreshToken'); // Retrieve the stored refresh token.
          // Make a request to your auth server to refresh the token.
          const response = await axios.post(`${baseURL}/token`, {
            refreshToken,
          });
          const { accessToken, refreshToken: newRefreshToken } = response.data;
          // Store the new access and refresh tokens.
          sessionStorage.setItem('accessToken', accessToken);
          sessionStorage.setItem('refreshToken', newRefreshToken);
          // Update the authorization header with the new access token.
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest); // Retry the original request with the new access token.
        } catch (refreshError) {
          // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
          // console.error('Token refresh failed:', refreshError);
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('refreshToken');
          const response = await axios.post(`${baseURL}/token`, {
                app_id: process.env.REACT_APP_CATALOG_APP_ID,
                secret_key: process.env.REACT_APP_CATALOG_SECRET_KEY,
                grant_type: 'secret_key'
            }, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            });
          const { access_token: newToken, refresh_token: newRefreshToken } = response.data;
          // console.log('berhasil dapat token', response)
          sessionStorage.setItem('accessToken', newToken);
          sessionStorage.setItem('refreshToken', newRefreshToken);
          return axiosInstance(originalRequest);
        }
      }
      return Promise.reject(error); // For all other errors, return the error as is.
    }
  );

  export async function getFetch(endpoint){
    try {
      const response = await axiosInstance.get(`${endpoint}`);
      // console.log('Data successfully fetched:', response.data);
      return response.data
    } catch (error) {
      // console.error('Error fetching data:', error);
      const errorObj = error.response.data.error;
      throw new Error(JSON.stringify(errorObj))
    }
  };
  export async function getFetchProduct(endpoint,param){
    let request;
    if(param){
        request = `${endpoint}${param}&per_page=18&search_column[]=published&search_text[]=1`;
    } else {
        request = `${endpoint}?per_page=18&search_column[]=published&search_text[]=1`;
    }
    
    try {
      const response = await axiosInstance.get(`${request}`);
      // console.log('Data successfully fetched:', response.data);
      return response.data
    } catch (error) {
      // console.error('Error fetching data:', error);
      const errorObj = error.response.data.error;
      throw new Error(JSON.stringify(errorObj))
    }
  };